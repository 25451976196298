// @import Dependencies
import PropTypes from 'prop-types';

// @import Mui Components
import { useTheme } from '@mui/material';

const Circle = ({ circleClasses, innerClasses, borderWidth = 1, shadow }) => {
  const theme = useTheme();
  return (
    <div className={`${circleClasses} absolute h-full w-full`}>
      <div
        className={`${innerClasses} w-full h-full rounded-[100%] border-solids bg-clip-padding`}
        style={{
          border: `${borderWidth}px solid ${theme.palette.loader.spinner.border}`,
          boxShadow: `inset 0px 0px 10px ${shadow}`,
          borderRight: 'none',
          borderTop: 'none',
        }}
      ></div>
    </div>
  );
};

export const WriterlySpinner = ({ variant = 'default' }) => {
  const theme = useTheme();
  const variants = {
    default: {
      border: 6,
      size: 100,
    },
    medium: {
      border: 4,
      size: 60,
    },
    small: {
      border: 3,
      size: 30,
    },
  };
  return (
    <div
      className="writerlyLoader"
      style={{
        width: `${variants[variant].size}px`,
        height: `${variants[variant].size}px`,
      }}
    >
      <Circle
        circleClasses={'rotate-[70deg]'}
        innerClasses={'writerlyLoaderCircle '}
        borderWidth={variants[variant].border}
        shadow={theme.palette.loader.spinner.shadow}
      />
      <Circle
        circleClasses={'rotate-[140deg]'}
        innerClasses={'writerlyLoaderCircle '}
        borderWidth={variants[variant].border}
        shadow={theme.palette.loader.spinner.shadow}
      />
      <Circle
        borderWidth={variants[variant].border}
        shadow={theme.palette.loader.spinner.shadow}
      />
      <Circle
        borderWidth={variants[variant].border}
        shadow={theme.palette.loader.spinner.shadowContrast}
      />
      <Circle
        borderWidth={variants[variant].border}
        shadow={theme.palette.loader.spinner.shadowContrast}
      />
    </div>
  );
};

WriterlySpinner.propTypes = {
  variant: PropTypes.string,
};
Circle.propTypes = {
  circleClasses: PropTypes.string,
  innerClasses: PropTypes.string,
  borderWidth: PropTypes.number,
  shadow: PropTypes.string,
};
