// @import Dependencies
import useThemeMode from '@hooks/useThemeMode';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @import custom Icons
import { list as svgList } from '@shared/Icon/List/index';

export const WriterlyIcon = ({ iconName, ...props }) => {
  const [svgRender, setSvgRender] = useState(null);
  const { themeMode } = useThemeMode();
  useEffect(() => {
    const iconFile =
      iconName?.charAt(0).toUpperCase() + iconName?.slice(1, iconName?.length);
    if (svgList.includes(iconFile)) {
      import(`../Icon/List/${iconFile}.jsx`).then((module) =>
        setSvgRender(module.default({ ...props }))
      );
    }
  }, [iconName, themeMode, props?.stroke, props?.svgFill]);

  return (
    <svg
      onClick={props.onClick}
      data-tip={props.dataTip}
      viewBox={svgRender ? svgRender.viewBox : '0 0 0 0'}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: props.svgFill ? `${props.svgFill}` : undefined,
        stroke: props.stroke ? `${props.stroke}` : undefined,
        width: props.width ? `${props.width}` : '20px',
        height: props.height ? `${props.height}` : '20px',
      }}
      fill={props.svgFill ? props.svgFill : 'none'}
      fillOpacity={props.svgOpacity}
      data-testid={props.dataTestId}
    >
      {svgRender?.path}
    </svg>
  );
};

WriterlyIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  dataTip: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  stroke: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  svgFill: PropTypes.string,
  svgOpacity: PropTypes.string,
  strokeWidth: PropTypes.string,
  strokeLinecap: PropTypes.string,
  onlyCount: PropTypes.bool,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
};
