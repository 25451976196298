export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          // Contained
          ...(ownerState.variant === 'contained' && {
            borderRadius: 10,
          }),
          // Contained Color Primary
          borderRadius: 10,
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              color: theme.palette.buttons.text,
              fontWeight: 700,
              backgroundColor: theme.palette.buttons.background,
              '&:hover': {
                backgroundColor: theme.palette.buttons.background,
                boxShadow: theme.palette.shadows[3],
              },
              '&.Mui-disabled': {
                color: theme.palette.buttons.text,
                backgroundColor: theme.palette.buttons.background,
                opacity: 0.4,
              },
            }),

          // Contained Color Secondary
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'secondary' && {
              color: theme.palette.common.white,
              border: `1px solid ${theme.palette.buttons.borderSecondary}`,
              fontWeight: 700,
              backgroundColor: theme.palette.buttons.backgroundSecondary,
              '&:hover': {
                backgroundColor: theme.palette.buttons.backgroundSecondary,
                boxShadow: theme.palette.shadows[3],
              },
              '&.Mui-disabled': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.common.backgroundSecondary,
                opacity: 0.4,
              },
            }),

          // Outlined
          ...(ownerState.variant === 'outlined' && {
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.text.primary}`,
            backgroundColor: 'transparent',
            borderRadius: 10,
            '&:hover': {
              color: theme.palette.text.primary,
              backgroundColor: 'transparent',
              boxShadow: theme.palette.shadows[3],
            },
            '&.Mui-disabled': {
              color: theme.palette.text.primary,
              backgroundColor: 'transparent',
              opacity: 0.4,
            },
          }),

          // Text
          ...(ownerState.variant === 'text' && {
            color: theme.palette.grey[400],
            textTransform: 'none',
            ...theme.typography.b1,
            '&:hover': {
              color: theme.palette.grey[400],
              backgroundColor: 'transparent',
            },
            '&.Mui-disabled': {
              color: theme.palette.buttons.text,
              opacity: 0.5,
            },
          }),
        }),
        sizeLarge: {
          height: 37,
          paddingLeft: 100,
          paddingRight: 100,
        },
        sizeMedium: {
          height: 37,
          paddingLeft: 40,
          paddingRight: 40,
        },
        sizeSmall: {
          paddingLeft: 20,
          paddingRight: 20,
        },
        startIcon: {
          color: theme.palette[600],

          '&:hover': {
            color: theme.palette.grey[900],
          },
        },
      },
    },
  };
}
