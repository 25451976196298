// @import Dependencies
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// @import Selectors
import { authPaths } from '@routes/paths';
import { selectUserData } from '@redux/slices/user';

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { loading } = useSelector(selectUserData);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loading) setLoaded(true);
  }, [loading]);

  const isAuthenticated =
    localStorage.getItem('accessToken') &&
    localStorage.getItem('emailVerified');

  if (!isAuthenticated) return <Navigate to={authPaths.root} />;
  if (loaded) return <>{children}</>;
}
