const rootElement = document.getElementById('root');

export default function Popper(theme) {
  return {
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
  };
}
