// @import Dependencies
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// @import Utilities
import { nomenclatureSnack } from '@utils/nomenclature';
import { pollRequest } from '@utils/slices';

// @import Services
import {
  semrushGetPhraseAPI,
  uploadPdfAPI,
  semrushUpdatePhraseAPI,
} from '@api/sharedJobs';

export const initialState = {
  semrush: {
    phrase_related: {
      loading: false,
      rows: [],
    },
    phrase_fullsearch: {
      loading: false,
      rows: [],
    },
  },
  file: {
    loading: false,
    data: null,
    uploadingError: false,
    isUploaded: false,
  },
  isStickyHeader: false,
  currentStickyProduct: null,
  selectedShop: null,
  detailTable: {
    individualResult: {
      switches: {
        showChanges: false,
        showDeletions: false,
        renderInputHTML: true,
        renderOutputHTML: true,
        renderOutputHTMLKeywords: false,
      },
      expandedRows: [],
      moderationCards: [],
    },
  },
};

// ------------------THUNKS----------------

const getPhrase = async (body, thunkAPI) => {
  const { data, isSuccessful, statusKey } = await semrushUpdatePhraseAPI(body);
  if (!isSuccessful) {
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
  const { pollData, pollStatusKey, isPollSuccessful } = await pollRequest({
    params: { id: data?.id },
    request: semrushGetPhraseAPI,
  });
  if (!isPollSuccessful) {
    return thunkAPI.rejectWithValue({
      data: pollData,
      statusKey: pollStatusKey,
    });
  }
  return pollData;
};

export const semrushGetPhraseRelated = createAsyncThunk(
  'sharedJobs/semrushGetPhraseRelated',
  getPhrase
);

export const semrushGetPhraseFullsearch = createAsyncThunk(
  'sharedJobs/semrushGetPhraseFullsearch',
  getPhrase
);

export const uploadPdf = createAsyncThunk(
  'sharedJobs/uploadPdf',
  async (formData, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await uploadPdfAPI(formData);
    const res = {
      name: formData.get('name'),
      size: formData.get('size'),
      ...data,
      statusKey,
    };
    if (isSuccessful) {
      return res;
    }
    return thunkAPI.rejectWithValue(res);
  }
);

export const sharedExtraReducers = (builder) => {
  builder
    .addCase(semrushGetPhraseRelated.pending, (state) => {
      state.semrush.phrase_related.loading = true;
    })
    .addCase(semrushGetPhraseRelated.fulfilled, (state, action) => {
      state.semrush.phrase_related.rows = action.payload;
      state.semrush.phrase_related.loading = false;
    })
    .addCase(semrushGetPhraseRelated.rejected, (state, action) => {
      state.semrush.phrase_related.loading = false;
    })
    .addCase(semrushGetPhraseFullsearch.pending, (state) => {
      state.semrush.phrase_fullsearch.loading = true;
    })
    .addCase(semrushGetPhraseFullsearch.fulfilled, (state, action) => {
      state.semrush.phrase_fullsearch.rows = action.payload;
      state.semrush.phrase_fullsearch.loading = false;
    })
    .addCase(semrushGetPhraseFullsearch.rejected, (state, action) => {
      state.semrush.phrase_fullsearch.loading = false;
    })
    .addCase(uploadPdf.pending, (state) => {
      state.file.loading = true;
      state.file.uploadingError = false;
      state.file.isUploaded = false;
    })
    .addCase(uploadPdf.fulfilled, (state, action) => {
      state.file.data = action?.payload;
      state.file.uploadingError = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
      state.file.loading = false;
      state.file.isUploaded = true;
    })
    .addCase(uploadPdf.rejected, (state, action) => {
      state.file.loading = false;
      state.file.uploadingError = true;
      state.file.isUploaded = false;
      nomenclatureSnack({ type: 'error', message: action?.payload?.statusKey });
    });
};

export const sharedJobsSlice = createSlice({
  name: 'sharedJobs',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    resetSemrushData: (state, action) => {
      state.semrush = initialState.semrush;
    },
    resetFileData: (state, action) => {
      state.file = initialState.file;
    },
    setFile: (state, action) => {
      state.file = action?.payload;
    },
    setIsStickyHeader: (state, action) => {
      state.isStickyHeader = action?.payload;
    },
    setSwitchShowChanges: (state, action) => {
      state.detailTable.individualResult.switches = {
        ...state.detailTable.individualResult.switches,
        showChanges: action?.payload,
        renderOutputHTML: true,
        showDeletions: false,
      };
    },
    setSwitchShowDeletions: (state, action) => {
      state.detailTable.individualResult.switches.showDeletions =
        action?.payload;
    },
    setSwitchRenderOutputHTML: (state, action) => {
      state.detailTable.individualResult.switches = {
        ...state.detailTable.individualResult.switches,
        renderOutputHTML: action?.payload,
        showChanges: false,
        showDeletions: false,
        renderOutputHTMLKeywords: false,
      };
    },
    setSwitchRenderOutputHTMLKeywords: (state, action) => {
      state.detailTable.individualResult.switches = {
        ...state.detailTable.individualResult.switches,
        renderOutputHTML: true,
        renderOutputHTMLKeywords: action?.payload,
      };
    },
    setSelectedShop: (state, action) => {
      state.selectedShop = action?.payload;
    },
    resetIndividualResultData: (state, action) => {
      state.detailTable = initialState.detailTable;
    },
    setSwitchRenderInputHTML: (state, action) => {
      state.detailTable.individualResult.switches.renderInputHTML =
        action?.payload;
    },
    setCurrentStickyProduct: (state, action) => {
      state.currentStickyProduct = action?.payload;
    },
    setExpandedRows: (state, action) => {
      state.detailTable.individualResult.expandedRows = action.payload;
    },
    setModerationCards: (state, action) => {
      state.detailTable.individualResult.moderationCards = action.payload;
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const {
  resetSemrushData,
  resetFileData,
  setFile,
  setIsStickyHeader,
  setSwitchShowChanges,
  setSwitchShowDeletions,
  setSwitchRenderInputHTML,
  setSwitchRenderOutputHTML,
  setSwitchRenderOutputHTMLKeywords,
  resetIndividualResultData,
  setCurrentStickyProduct,
  setExpandedRows,
  setModerationCards,
  setSelectedShop,
} = sharedJobsSlice.actions;
export default sharedJobsSlice.reducer;

// ------------------SELECTORS-------------

export const selectedPhraseRelatedLoader = (state) =>
  state.sharedJobs.semrush.phrase_related.loading;

export const selectedPhraseFullsearchLoader = (state) =>
  state.sharedJobs.semrush.phrase_fullsearch.loading;

export const selectedPhraseRelatedRows = (state) =>
  state.sharedJobs.semrush.phrase_related.rows;

export const selectedPhraseFullsearchRows = (state) =>
  state.sharedJobs.semrush.phrase_fullsearch.rows;

export const selectedPdfFileUploadLoading = (state) =>
  state.sharedJobs.file.loading;

export const selectedPdfFileUploadingError = (state) =>
  state.sharedJobs.file.uploadingError;

export const selectedPdfFileIsUploaded = (state) =>
  state.sharedJobs.file.isUploaded;

export const selectedSharedShop = (state) => state.sharedJobs.selectedShop;

export const selectedPdfFileUploadedData = (state) =>
  state.sharedJobs.file.data;
export const selectedIsStickyHeader = (state) =>
  state.sharedJobs.isStickyHeader;
export const selectIndividualResultSwitches = (state) =>
  state.sharedJobs.detailTable.individualResult.switches;
export const selectIndividualExpandedRows = (state) =>
  state.sharedJobs.detailTable.individualResult.expandedRows;
export const selectIndividualModerationCards = (state) =>
  state.sharedJobs.detailTable.individualResult.moderationCards;
export const selectCurrentStickyProduct = (state) =>
  state.sharedJobs.currentStickyProduct;
