// @import Dependencies
import React from 'react';
import ReactDOM from 'react-dom/client';
import store from '@redux/store';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// @import Utils
import '@utils/highlight';
import reportWebVitals from 'src/reportWebVitals';

// @import Components
import App from 'src/App';
import { WriterlySnackBar } from '@shared/SnackBar/SnackBar';

// import Config Files
import '@translation/i18n';
import { router } from '@routes/routes';

// @import Providers
import { Provider } from 'react-redux';
import { UserProvider } from '@contexts/UserContext';
import { SnackbarProvider } from 'notistack';
import { ThemeModeProvider } from '@contexts/ThemeModeContext';
import ThemeProvider from '@theme/theme';
import SentryProvider from '@providers/sentry';
import TagManagerProvider from '@providers/gtm';

// import Styles
import 'src/index.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <SentryProvider dsn={import.meta.env.VITE_SENTRY_DSN}>
        <TagManagerProvider gtmId={import.meta.env.VITE_GTM_ID}>
          <UserProvider>
            <ThemeModeProvider>
              <ThemeProvider>
                <SnackbarProvider
                  preventDuplicate
                  Components={{
                    writerlySnackBar: WriterlySnackBar,
                  }}
                >
                  <RouterProvider router={createBrowserRouter(router)}>
                    <App />
                  </RouterProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </ThemeModeProvider>
          </UserProvider>
        </TagManagerProvider>
      </SentryProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
