import 'src/App.css';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authPaths } from '@routes/paths';

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(authPaths.home);
  }, []);

  return <div className="App">...Redirecting</div>;
}

export default App;
