const sharedCodes = {
  400000: 'Some field is missing or wrong',
  401000:
    'Authentication credentials were not provided. Please provide valid credentials and try again',
  403000:
    'You are not authorized to access this resource. Please contact the administrator',
  404000:
    'The requested resource could not be found. Please check your URL and try again.',
  429000: 'Too many requests',
  500000:
    'An unexpected error occurred. Please try again later or contact the administrator',
  503000:
    'Sorry, we are experiencing technical difficulties with a third-party integration at this time',
  504000:
    'The request timed out. Please try again later or contact the administrator',
};

const messages = {
  default:
    'Something went wrong, please try again or contact the administrator',
  'GET_api-integration/': {
    ...sharedCodes,
  },
  'POST_api-integration/': {
    ...sharedCodes,
    201: 'CMS Connected successfully',
    400014: 'The API integration already exists',
    401005: 'Must be owner',
    500000: 'Error in CMS connection',
  },
  'POST_api-integration-connect/': {
    ...sharedCodes,
    200: 'Redirecting to your CMS',
    400014: 'The API integration already exists',
    401005: 'Must be owner',
    403008:
      'This store has already been connected to EKOM via another account - reach out to your team and see who got here first',
    400019: 'That shop has already been added to your account',
    500000: 'Error in CMS connection',
  },
  'GET_api-integration/products/{id}/': {
    ...sharedCodes,
  },
  'POST_api-integration/request-new-integration/': {
    ...sharedCodes,
    201: 'The integration was successfully requested',
    500000:
      'Oops, something went wrong while requesting the integration, please try again',
  },
  'GET_api-integration/{id}/': {
    ...sharedCodes,
  },
  'PATCH_api-integration/{id}/': {
    ...sharedCodes,
    200: 'CMS Connection edited successfully',
    500000: 'Error during CMS connection edition',
  },
  'DELETE_api-integration/{id}/': {
    ...sharedCodes,
    204: 'CMS Connection removed successfully',
    500000: 'Error during CMS connection removal',
  },
  'POST_api-integration/request-custom-integration': {
    ...sharedCodes,
    201: 'Request was sent successfully',
  },
  'POST_auth/change-password': {
    ...sharedCodes,
    201: 'The new password was successfully saved',
    400003: 'Old password wrong',
    500000:
      'Oops, something went wrong while changing your password, please try again',
  },
  'GET_auth/email-verification': {
    ...sharedCodes,
    400007: 'Email already verified',
  },
  'GET_auth/environment-variables': { ...sharedCodes },
  'POST_auth/forgot-password': {
    ...sharedCodes,
    400004: 'Invalid Email',
    503002: 'Email service unavailable',
    404001: 'User not found, please check and try again',
  },
  'POST_auth/google': {
    ...sharedCodes,
    401001: 'Something wrong with google authentication',
    500002: 'Something wrong with subscribe (by nickname)',
    503001: 'Third-Party Service Unavailable.',
  },
  'POST_auth/login': {
    ...sharedCodes,
    400006: 'Credentials were not provided.',
    401004: 'Incorrect authentication credentials.',
    403002: 'Team Member is inactive.',
  },
  'POST_auth/logout': {
    ...sharedCodes,
    400001: 'Invalid token',
  },
  'POST_auth/register': {
    ...sharedCodes,
    500001: 'An account with this email already exists',
    404000: 'The invitation code has expired',
  },
  'GET_invitation/{code}': {
    ...sharedCodes,
    404000: 'The invitation code has expired',
  },
  'POST_auth/reset-password/{token}/': {
    ...sharedCodes,
    400001: 'Invalid token',
    400005: 'The same password was used',
    400006: 'Credentials were not provided.',
    401002: 'The reset password link has expired',
  },
  'GET_auth/verify-email/{token}/': {
    ...sharedCodes,
    200: 'The email {{email}} has been confirmed successfully!',
    400001: 'Invalid token',
    400007: 'Email already verified',
    401003: 'The email verification link has expired',
    500002: 'Something wrong with subscribe (by nickname)',
  },
  'POST_automation-push': {
    ...sharedCodes,
    201: 'Outputs pushed to CMS successfully',
  },
  'POST_autopilot-push': {
    ...sharedCodes,
    201: 'Outputs pushed to CMS successfully',
  },
  'GET_automation/': { ...sharedCodes },
  'POST_automation/': {
    ...sharedCodes,
    201: 'Automation Created Successfully',
    400012: 'Headers received in the columnMapping must exist in csv file',
    400013:
      'All input fields from the template must exist in the columnMapping.',
    403005: 'Rows limit exceeded',
    403006: 'This order exceeds the row limits',
    404004: 'The collection is empty',
    500000:
      'Oops, something went wrong while creating your automation, please try again',
  },
  POST_optimize: {
    ...sharedCodes,
    201: 'Optimization Created Successfully',
    403005: 'Rows limit exceeded',
    403006: 'This order exceeds the row limits',
    404004: 'The collection is empty',
    500000:
      'Oops, something went wrong while creating your optimization, please try again',
  },
  'GET_automation/{id}/': { ...sharedCodes },
  'PATCH_automation/{id}/': {
    ...sharedCodes,
    200: 'Asset updated successfully!',
  },
  'POST_google-search-console/test-indexing/{id}/': {
    ...sharedCodes,
    201: 'Automatic Index is available',
    404005: 'Unable to confirm Search Console owner-status',
  },
  'GET_automation/{id}/': { ...sharedCodes },
  'PATCH_automation/{id}/': {
    ...sharedCodes,
    200: 'Asset updated successfully!',
  },
  'POST_billing/check-payment': { ...sharedCodes },
  'GET_billing/plans-tiers': { ...sharedCodes },
  'POST_billing/portal-link': {
    ...sharedCodes,
    200: 'Redirecting to customer portal...',
    500003: 'Something wrong with the billing portal',
    500000: 'Error redirecting to customer portal',
  },
  'POST_billing/portal-link-checkout': {
    ...sharedCodes,
    200: 'Redirecting to customer portal...',
    500003: 'Something wrong with the billing portal',
  },
  'POST_billing/change-plan': {
    ...sharedCodes,
    201: 'Redirecting to shopify...',
  },
  'POST_billing/cancel-plan': {
    ...sharedCodes,
    201: 'Plan canceled successfully',
  },
  'POST_csv-batch-upload/': {
    ...sharedCodes,
    403005: 'Rows limit exceeded',
    403006: 'This order exceeds the row limits',
  },
  'GET_csvfile/': { ...sharedCodes },
  'POST_csvfile/': {
    ...sharedCodes,
    500000: 'Something went wrong uploading the file, please try again',
  },
  'GET_csvfile/{id}/': { ...sharedCodes },
  'DELETE_csvfile/{id}/': {
    ...sharedCodes,
    500000: 'Something went wrong deleting the file, please try again',
  },
  'GET_document/': { ...sharedCodes },
  'POST_document/': {
    ...sharedCodes,
    200: 'Document created successfully',
    500000:
      'Oops, something went wrong while creating your document, please try again',
  },
  'GET_document/{uuid}/': {
    ...sharedCodes,
    400017: 'Invalid Document Id',
  },
  'PATCH_document/{uuid}/': {
    ...sharedCodes,
    200: 'Document Updated Successfully',
    500000:
      'Oops, something went wrong while updating your document, please try again',
  },
  'DELETE_document/{uuid}/': {
    ...sharedCodes,
    204: 'Document deleted successfully',
    400017: 'Invalid Document Id',
    500000:
      'Oops, something went wrong while deleting your document, please try again',
  },
  'GET_get-desired-seo': { ...sharedCodes },
  'GET_greg-chat/': { ...sharedCodes },
  'POST_greg-chat/': { ...sharedCodes },
  'GET_greg-chat/{id}/': { ...sharedCodes },
  'GET_greg-completion/': { ...sharedCodes },
  'POST_greg-completion/': { ...sharedCodes },
  'GET_greg-completion/{id}/': { ...sharedCodes },
  'GET_greg-product/': { ...sharedCodes },
  'POST_greg-product/': { ...sharedCodes },
  'GET_greg-product/{id}/': { ...sharedCodes },
  'POST_persona-custom/': {
    ...sharedCodes,
    200: 'The {{persona}} persona was successfully saved',
    403004: 'Persona limit exceeded',
    503001: 'Third-Party Service Unavailable.',
    500000:
      'Oops, something went wrong while creating your persona, please try again',
  },
  'PUT_persona-custom/{id}/': { ...sharedCodes },
  'PATCH_persona-custom/{id}/': {
    ...sharedCodes,
    200: 'The {{persona}} persona was successfully updated',
    500000:
      'Oops, something went wrong while updating your persona, please try again',
  },
  'GET_persona-filetraining-by-persona/{id}/': { ...sharedCodes },
  'GET_persona-filetraining/': { ...sharedCodes },
  'POST_persona-filetraining/': {
    ...sharedCodes,
    200: "The '{{file}}' was uploaded successfully",
    413000: 'Content Too Large.',
    415000: 'The file type is not supported.',
    500004: 'Internal Server Error occured during the file upload process.',
    500000: "Error uploading '{{file}}",
  },
  'GET_persona-filetraining/{id}/': { ...sharedCodes },
  'DELETE_persona-filetraining/{id}/': {
    ...sharedCodes,
    204: "The '{{file}}' was deleted successfully",
    500000: "Error deleting '{{file}}",
  },
  'POST_persona-standard/': {
    ...sharedCodes,
    200: 'The {{persona}} persona was successfully saved',
    500000:
      'Oops, something went wrong while creating your persona, please try again',
  },
  'PUT_persona-standard/{id}/': { ...sharedCodes },
  'PATCH_persona-standard/{id}/': {
    ...sharedCodes,
    200: 'The {{persona}} persona was successfully updated',
    500000:
      'Oops, something went wrong while updating your persona, please try again',
  },
  'GET_persona/': { ...sharedCodes },
  'GET_persona/{id}/': { ...sharedCodes },
  'DELETE_persona/{id}/': {
    ...sharedCodes,
    204: "The '{{persona}}' persona was successfully deleted",
    500000:
      'Oops, something went wrong while deleting your persona, please try again',
  },
  'POST_phrase/': { ...sharedCodes },
  'GET_phrase/{id}': { ...sharedCodes },
  'GET_prompt-template/custom-template/': { ...sharedCodes },
  'POST_prompt-template/custom-template/': {
    ...sharedCodes,
    200: '{{template}} Created Successfully',
    400015: 'No prompt generated',
    400016: 'Some of the columns do not match the required inputs',
    403001: 'Has not paid plan',
    403007: 'Custom Template limit exceeded',
    500000: 'Oops, something went wrong creating your custom template',
  },
  'GET_prompt-template/custom-template/{id}/': { ...sharedCodes },
  'PATCH_prompt-template/custom-template/{id}/': {
    ...sharedCodes,
    200: 'Template updated Successfully',
    400015: 'No prompt generated',
    400016: 'Some of the columns do not match the required inputs',
  },
  'DELETE_prompt-template/custom-template/{id}/': {
    ...sharedCodes,
    204: 'The template was deleted successfully',
  },
  'GET_prompt_templates/': { ...sharedCodes },
  'DELETE_prompt_templates/choice/{id}/': { ...sharedCodes },
  'GET_prompt_templates/{id}/': { ...sharedCodes },
  'GET_prompt_templates/{id}/history/': {
    ...sharedCodes,
    200: 'Successfully retrieved {{subcategory}} history',
    500000: 'Oops, something went wrong while getting your creation history',
  },
  'GET_public-shop/': { ...sharedCodes },
  'POST_public-shop/': { ...sharedCodes },
  'GET_public-shop/{id}/': { ...sharedCodes },
  'GET_public-shop/{id}/metadata': { ...sharedCodes },
  'GET_google-search-console/authorization/': {
    200: 'Redirecting to Google',
    ...sharedCodes,
  },
  'PATCH_request-row-to-openai/{id}': { ...sharedCodes },
  'POST_team_member/add': {
    ...sharedCodes,
    200: 'User invited succesfully!',
    400007: 'Email already verified',
    401006: 'Must be admin',
    500000:
      'An error occurred, make sure of type a valid email or try again later',
  },
  'GET_team_member/members': {
    ...sharedCodes,
    401005: 'Must be owner',
  },
  'PATCH_team_member/modify/{id}/': {
    ...sharedCodes,
    200: 'Team member updated successfully',
    404001: 'User not found',
  },
  'POST_team_member/register': {
    ...sharedCodes,
    201: 'Account Created successfully',
    400009: 'All seat used',
    500000:
      'Oops, something went wrong while creating your account, please try again',
  },
  'POST_team_member/remove': {
    ...sharedCodes,
    204: 'Team Member Successfully Deactivated',
    400008: 'Owner cannot be removed',
    500000: 'Error Deleting Team Member',
  },
  'GET_user-avatar-decode/': { ...sharedCodes },
  'GET_user-details/': {
    ...sharedCodes,
    404001: 'User not found',
  },
  'POST_user-report-bug/': {
    ...sharedCodes,
    200: 'The bug was successfully reported!',
    500000:
      'Oops, something went wrong while reporting the bug, please try again',
  },
  'POST_user-request-plan-upgrade/': {
    ...sharedCodes,
    201: 'The upgrade was successfully requested',
    500000:
      'Oops, something went wrong while requesting the upgrade, please try again',
  },
  'GET_user/': {
    ...sharedCodes,
    404001: 'User not found',
  },
  'PATCH_user/': {
    ...sharedCodes,
    200: 'User updated successfully',
    404001: 'User not found',
    500000: 'Error updating your account',
  },
  'GET_workflows-batchrows/{id}': { ...sharedCodes },
  'PATCH_workflows-batchrows/{id}': {
    ...sharedCodes,
    200: 'Result Edited Successfully - progress saved',
  },
  'POST_automation-rerun-post/{id}': {
    ...sharedCodes,
    403005: 'Rows limit exceeded',
  },
  'GET_automation-rerun-get/{id}': {
    ...sharedCodes,
    200: 'Result Updated Successfully',
    403005: 'Rows limit exceeded',
  },
  'POST_write/': {
    ...sharedCodes,
    403003: 'Word limit exceeded',
    404002: 'Private prompt',
    404003: 'Not authorized to use this prompt',
  },
  'GET_autopilot-job-csv/{id}': { ...sharedCodes },
  'GET_autopilot-job/': { ...sharedCodes },
  'POST_autopilot-job/': { ...sharedCodes },
  'GET_autopilot-job/{id}/': { ...sharedCodes },
  'PATCH_autopilot-job/{id}/': { ...sharedCodes },
  'GET_autopilot-reoptimize-get/{id}': {
    ...sharedCodes,
    200: 'Reoptimization was successful!',
    403005: 'Rows limit exceeded',
  },
  'POST_autopilot-reoptimize-post/{id}': {
    ...sharedCodes,
    403005: 'Rows limit exceeded',
  },
  'GET_automation-reoptimize-get/{id}': {
    ...sharedCodes,
    200: 'Reoptimization was successful!',
    403005: 'Rows limit exceeded',
  },
  'POST_automation-reoptimize-post/{id}': {
    ...sharedCodes,
    403005: 'Rows limit exceeded',
  },
  'GET_autopilot-rerun-get/{id}': {
    ...sharedCodes,
    200: 'Result Updated Successfully',
    403005: 'Rows limit exceeded',
  },
  'POST_autopilot-rerun-post/{id}': {
    ...sharedCodes,
    403005: 'Rows limit exceeded',
  },
  'PATCH_autopilot-review-admin/{id}': { ...sharedCodes },
  'GET_autopilot-rows/{id}': { ...sharedCodes },
  'PATCH_autopilot-rows/{id}': {
    ...sharedCodes,
    200: 'Result Edited Successfully - progress saved',
  },
  'GET_autopilot/{id}/': { ...sharedCodes },
  'PATCH_autopilot/{id}/': {
    ...sharedCodes,
    200: 'Optimization settings updated successfully',
    500000: 'Error updating autopilot',
  },
  'PATCH_autopilot-job/{id}/': {
    ...sharedCodes,
    200: 'Asset updated successfully!',
    500000: 'Error updating asset',
  },
  'GET_pdp/': { ...sharedCodes },
  'GET_api-integration/categories/{id}/': { ...sharedCodes },
  'GET_api-integration/pull/{id}/': { ...sharedCodes },
  'GET_pdf/{id}/': { ...sharedCodes },
  POST_pdf: {
    201: 'Your file has been uploaded successfully',
    ...sharedCodes,
  },
  'GET_activity-log/{type}': {
    ...sharedCodes,
  },
  'POST_undo-content-update/{id}': {
    201: 'Undo content successfully',
    ...sharedCodes,
  },
  'GET_performance/shop/{id}': { ...sharedCodes },
  'GET_performance/product/{id}': { ...sharedCodes },
  'GET_performance/push/{id}': { ...sharedCodes },
  'GET_pushed-products/{id}': { ...sharedCodes },
  'GET_product-pulls/{id}': { ...sharedCodes },
  'GET_autopilot-rescore-get/{id}': {
    ...sharedCodes,
    200: 'Score updated successfully',
  },
  'POST_autopilot-rescore-post/{id}': { ...sharedCodes },
  'GET_onboarding-job/{id}': { ...sharedCodes },
  'GET_onboarding-job/{id}': { ...sharedCodes },
  'POST_onboarding-rerun-post/{id}': { ...sharedCodes },
  'GET_onboarding-rerun-get/{id}': { ...sharedCodes },
  'POST_sample-rerun-post/{id}': {
    201: 'Thanks for your feedback!',
    ...sharedCodes,
  },
  'GET_dashboard-statistics-cards/{id}': { ...sharedCodes },
  'GET_dashboard/performance/products-table/{id}': { ...sharedCodes },
  'GET_dashboard/top-performance/products/{id}': { ...sharedCodes },
  'GET_dashboard/optimization/history-log/{id}': {
    ...sharedCodes,
    200: 'Optimization history log retrieved successfully',
    404000: 'Optimization history log not found',
    500000: 'Error retrieving optimization history log',
  },
  'GET_optimization-history-logs-details/{id}': {
    ...sharedCodes,
    200: 'Optimization log product details retrieved successfully',
    404000: 'Optimization log product details not found',
    500000: 'Error retrieving optimization log product details',
  },
  'GET_optimization-log/previous-rounds/{rowId}': {
    ...sharedCodes,
    200: 'Previous rounds options retrieved successfully',
    404000: 'Previous rounds options not found',
    500000: 'Error retrieving previous rounds options',
  },
  'POST_optimization-log/restore-super-row': {
    ...sharedCodes,
    200: 'Content restored successfully',
    404000: 'Previous content is not the latest version',
    500000: 'Error restoring product content',
  },
};

export default messages;
