import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Translations
import enLocales from '@translation/en/en.json' assert { type: 'json' };
import enNomenclatureLocales from '@translation/en/enResponseNomenclature.js';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translations: enLocales,
        nomenclature: enNomenclatureLocales,
      },
    },
    lng: 'en', // if you're using a language detector, do not define the lng option
    ns: ['translations', 'nomenclature'],
    defaultNS: 'translations',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
