import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { authPaths } from '@routes/paths';
GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const isAuthenticated =
    localStorage.getItem('accessToken') &&
    localStorage.getItem('emailVerified');
  if (isAuthenticated) {
    const redirectTo = authPaths.home;
    return <Navigate to={redirectTo} />;
  }
  return <>{children}</>;
}
