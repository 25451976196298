import { makeRequest } from '@api/client';

const getOptimizationLogStatisticsCardsAPI = async (id) => {
  return await makeRequest(`dashboard/statistics/cards/${id}`, {
    method: 'GET',
  });
};

const getOptimizationHistoryLogAPI = async ({ id, params = {} }) => {
  const queryParams = new URLSearchParams(params).toString();
  return await makeRequest(
    `dashboard/optimization/history-log/${id}?${queryParams}`,
    {
      method: 'GET',
    }
  );
};

const getOptimizationLogProductDetailsAPI = async ({ id, params }) => {
  const queryParams = new URLSearchParams(params).toString();
  return await makeRequest(
    `optimization-history-logs-details/${id}?${queryParams}`,
    {
      method: 'GET',
    }
  );
};

const getOptimizationLogProductMetricsAPI = async ({ shopId, rowId }) => {
  return await makeRequest(
    `optimization-history-logs-metrics/${shopId}/${rowId}`,
    {
      method: 'GET',
    }
  );
};

const getOtherRoundsOptionsAPI = async ({ rowId, params }) => {
  const queryParams = new URLSearchParams(params).toString();
  return await makeRequest(
    `optimization-log/previous-rounds/${rowId}?${queryParams}`,
    {
      method: 'GET',
    }
  );
};

const restoreSuperRowAPI = async ({ body }) => {
  return await makeRequest(`optimization-log/restore-super-row`, {
    method: 'POST',
    body,
  });
};

export {
  getOptimizationLogStatisticsCardsAPI,
  getOptimizationHistoryLogAPI,
  getOptimizationLogProductDetailsAPI,
  getOtherRoundsOptionsAPI,
  getOptimizationLogProductMetricsAPI,
  restoreSuperRowAPI,
};
