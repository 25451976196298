import { makeRequest } from '@api/client';

const uploadPdfAPI = async (body) => {
  return await makeRequest(`pdf`, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getPdfByIdAPI = async (id) => {
  return await makeRequest(`pdf/${id}`, {}, { isBlob: true });
};

const semrushUpdatePhraseAPI = async (body) => {
  return await makeRequest(`phrase/`, {
    method: 'POST',
    body,
  });
};

const semrushGetPhraseAPI = async ({ id }) => {
  return await makeRequest(`phrase/${id}`, {
    method: 'GET',
  });
};

export {
  uploadPdfAPI,
  getPdfByIdAPI,
  semrushUpdatePhraseAPI,
  semrushGetPhraseAPI,
};
