export default function Menu(theme) {
  const rootElement = document.getElementById('root');
  return {
    MuiMenu: {
      defaultProps: {
        container: rootElement,
      },
      styleOverrides: {
        root: {
          marginTop: '10px',
        },
        paper: {
          fontSize: '1rem',
          overflow: 'visible!important',
          boxShadow: 'none!important',
          border: `1px solid ${theme.palette.text.secondary}`,
          mt: 1.5,
          borderRadius: '5px!important',
          background: theme.palette.background.default,
        },
        list: {
          boxShadow: 'none!important',
          '.MuiButtonBase-root': {
            ml: -0.5,
            mr: 1,
            width: '100%',
            background: theme.palette.background.default,
          },
        },
      },
      variants: [
        {
          props: { variant: 'menu' },
          style: {
            '.MuiMenu-paper': {
              fontSize: '1rem',
              overflow: 'visible!important',
              boxShadow: 'none!important',
              mt: 1.5,
              borderRadius: '5px!important',
              background: theme.palette.background.default,
              '::before': {
                content: '""',
                display: 'block!important',
                position: 'absolute!important',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                background: theme.palette.background.default,
                transform: 'translateY(-50%) rotate(45deg)!important',
                zIndex: 0,
                borderLeft: `1px solid ${theme.palette.text.secondary}`,
                borderTop: `1px solid ${theme.palette.text.secondary}`,
                borderRadius: '0px 0px 0px 0px',
              },
            },
          },
        },
        {
          props: { variant: 'selectedMenu' },
          style: {
            '.MuiMenu-paper': {
              fontSize: '1rem',
              overflow: 'visible!important',
              boxShadow: 'none!important',
              mt: 1.5,
              background: theme.palette.background.default,
              borderRadius: '5px!important',
              '::before': {
                content: '""',
                display: 'block!important',
                position: 'absolute!important',
                top: '15px',
                left: '-6px',
                width: 9,
                height: 10,
                background: theme.palette.background.default,
                transform: 'translateY(-50%) rotate(145deg)!important',
                zIndex: 0,
                borderRight: `1px solid ${theme.palette.text.secondary}`,
                borderBottom: `1px solid ${theme.palette.text.secondary}`,
                borderRadius: '0px 0px 1px 0px',
              },
            },
          },
        },
      ],
    },
  };
}
