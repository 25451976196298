// @import Reducers
import { toggleAccessDeniedModal } from '@redux/slices/navigation';
// @import Utils
import { nomenclatureSnack } from '@utils/nomenclature';

export const pollRequest = async ({
  params,
  request,
  seconds = 1,
  code = 404000,
}) => {
  const { data, isSuccessful, statusKey } = await request({
    ...params,
  });

  if (!isSuccessful && data?.status === code) {
    await new Promise((resolve) => setTimeout(resolve, seconds * 1000));
    return await pollRequest({
      params,
      request,
      seconds,
      code,
    });
  }
  return {
    pollData: data,
    pollStatusKey: statusKey,
    isPollSuccessful: isSuccessful,
  };
};

const ROW_LIMIT_EXCEEDED_STATUS_CODES = [403005, 403006];

// Open Access denied modal instead or show snack
export const handleRowLimitException = ({
  statusKey,
  dispatch,
  showSnack = true,
}) => {
  if (
    ROW_LIMIT_EXCEEDED_STATUS_CODES.some((code) => statusKey.includes(code))
  ) {
    dispatch(toggleAccessDeniedModal({ isVisible: true, titleKey: 'hasPDP' }));
  } else {
    if (showSnack) {
      nomenclatureSnack({
        type: 'error',
        message: statusKey,
      });
    }
  }
};
