import React from 'react';
import dayjs from 'dayjs';
import defaultsDeep from 'lodash/defaultsDeep';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import MD5 from 'crypto-js/md5';

import { useIntl } from 'react-intl';

export const getCookie = (cookieName) => {
  if (document && document.cookie) {
    return document.cookie.replace(
      new RegExp(`(?:(?:^|.*;\\s*)${cookieName}\\s*\\=\\s*([^;]*).*$)|^.*$`),
      '$1'
    );
  }
};

export default {
  createReducer(initialState, handlers) {
    return (state = initialState, action) => {
      if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
        return handlers[action.type](state, action);
      }
      return state;
    };
  },

  combineClassNames(...classNames) {
    try {
      return classNames.filter((c) => c).join(' ');
    } catch (ex) {
      return '';
    }
  },

  getValueFromDeepObject(parentAttr, attr, defaultValue) {
    if (isNil(parentAttr)) {
      return null;
    }

    if (isNumber(parentAttr[attr]) || !isNil(parentAttr[attr])) {
      return parentAttr[attr];
    }

    return defaultValue;
  },

  md5(text) {
    const hash = MD5(text).toString();
    return hash;
  },
  getCookie,
};

/**
 * Convert an object to URL params
 * @param {object} params - An object with the keys and the values of the URL params.
 *
 * @returns {string} An URL params.
 *                   Example: my_first_param=my_value&another_param=another_value
 */
export const stringifyParams = (params) => {
  if (!params) return '';
  return Object.entries(params)
    .filter(({ 1: value }) => !isNil(value) && !Array.isArray(value))
    .map(([key, value]) => {
      const encodeKey = encodeURIComponent(key).replace(/!/g, '%21');
      return value === null
        ? encodeKey
        : `${encodeKey}=${encodeURIComponent(value)}`;
    })
    .sort()
    .join('&');
};

/**
 * Convert URL params to an object
 * @param {string} params - An URL params.
 *                          Example: my_first_param=my_value&another_param=another_value
 *
 * @returns {object} An object with the keys and the values of the URL params.
 */
export const paramsToObject = (params) => {
  const dic = {};

  if (typeof params !== 'string') {
    return dic;
  }

  const searchParams = new URLSearchParams(params);

  for (const [key, value] of searchParams) {
    if (value === '') {
      continue;
    }
    dic[key] = value;
  }
  return dic;
};

/**
 * Return an object with all the props that fulfill a certain naming pattern.
 *
 * @param {(Component|PureComponent)} instance - The instance of the component to take the props from it.
 * @param {RegExp} regexp - The regular expression that represents the pattern you'll be searching for.
 * @param {Boolean} remove - Determines if the regular expression should be removed when transmitting the keys to the
 *                           new object.
 *
 * @returns {Object} - An object containing only the props that fulfills the passed pattern.
 */
export const getPropsWith = (instance, regexp, remove = false) => {
  const object = {};

  Object.keys(instance.props).forEach((key) => {
    if (key.search(regexp) !== -1) {
      const objectKey = remove ? key.replace(regexp, '') : key;
      object[objectKey] = instance.props[key];
    }
  });

  return object;
};

export const openIntercom = (ev, isWindowOpen) => {
  ev.preventDefault();
  ev.stopPropagation();

  if (window.Intercom && window.Intercom.booted) {
    const intercomContainer = document.getElementById('intercom-container');
    intercomContainer.style.display = null;

    window.Intercom('showNewMessage');
    window.Intercom('onHide', () => {
      if (isWindowOpen) {
        intercomContainer.style.display = 'none';
      }
    });
  }
};

/**
 * Calculate how much is the text size in the DOM
 * @param {string|array} text Text to measure
 * @param {number} delta Sumatory of other factors to affect the text size (margin, padding, etc)
 */
export const getDomTextSize = (
  text,
  widthDelta = 40,
  heightDelta = 0,
  styles = {},
  tag = 'span'
) => {
  const e = document.createElement(tag);
  e.textContent = text;

  if (text instanceof Array) {
    e.textContent = '';
    text.forEach((item) => {
      const element = document.createElement(tag);
      element.textContent = item;
      e.appendChild(element);
    });
  }
  // Adding styles
  Object.keys(styles).forEach((key) => {
    e.style[key] = styles[key];
  });

  const width = document.body.appendChild(e).offsetWidth;
  const height = document.body.appendChild(e).offsetHeight;
  e.remove();
  return { width: width + widthDelta, height: height + heightDelta };
};

/**
 * Validate if it's necessary make a new request to get more data
 * @param {number} page Current front page
 * @param {number} rowsPerPage Rows per page
 * @param {number} serverActualApiPage Current server page
 * @param {number} serverPageSize Server page size
 * @param {number} limit Limit. Default: 80%
 */
export const needsNewPageRequest = (
  page,
  rowsPerPage,
  serverActualApiPage = 1,
  serverPageSize = 100,
  limit = 0.8
) => rowsPerPage * page > serverPageSize * serverActualApiPage * limit;

/**
 * Get the data limits information from the window variable.
 * It should return a YYYY-MM-DD formatted string.
 * If no data limits variable is present, it should return null.
 * @returns {string} A YYYY-MM-DD formatted string
 */
export const getDataLimitDate = () => {
  const { dataLimits } = window;

  if (dataLimits == null) return null;

  const currentDate = dayjs();
  const subtractedDate = currentDate.clone().subtract(dataLimits, 'months');
  // This will return a 'YYYY-MM-DD' string
  return subtractedDate.format('YYYY-MM-DD');
};

export function useCombinedLocalizationTexts(userProvided, defaultFn) {
  const { formatMessage } = useIntl();
  const defaults = defaultFn(formatMessage);
  return defaultsDeep({}, userProvided, defaults);
}

export function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false);
  React.useLayoutEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);
  return React.useCallback(
    (...args) => (mounted.current ? dispatch(...args) : () => {}),
    [dispatch]
  );
}
