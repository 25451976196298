// @import Dependencies
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

const SentryProvider = ({ dsn, children }) => {
  useEffect(() => {
    const environment = import.meta.env.VITE_ENV;
    const AVOID_EXCEPTIONS = [
      'UnhandledRejection: Object captured as promise rejection with keys:',
      'Failed to fetch dynamically imported module:',
    ];
    // Sentry Initialization
    if (environment !== 'develop' && dsn) {
      Sentry.init({
        dsn: dsn,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        environment,
        ignoreErrors: [
          /NotAllowedError/,
          /SyntaxError: webkit-masked-url:\/\/hidden\//,
          'AbortError',
          'ResizeObserver loop completed with undelivered notifications',
        ],
        beforeSend(event) {
          const exception =
            event.exception?.values && event.exception?.values[0];
          if (
            AVOID_EXCEPTIONS.some((ex) => exception?.value?.includes(ex)) ||
            event?.extra?.notReport
          )
            return null;

          if (
            event.request &&
            event.request.url &&
            event.request.url.includes('https://t.mm-uxrv.com/x/sax')
          )
            return null;

          return event;
        },
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }
  }, [dsn]);

  return <>{children}</>;
};

SentryProvider.propTypes = {
  dsn: PropTypes.any,
  children: PropTypes.node,
};

export default SentryProvider;
