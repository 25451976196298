// @import Dependencies
import * as Yup from 'yup';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import i18n from '@translation/i18n';

export const optimizeAssetFrequencyValues = (totalProducts, totalPDPs) => {
  let options = [];
  if (totalProducts && totalProducts < 10) {
    options = Array.from({ length: totalProducts + 1 }).map((_, index) => {
      return {
        label: `${index}`,
        value: index,
        showLabel: true,
      };
    });
  } else {
    options = Array.from({ length: 11 }).map((_, index) => {
      return {
        label: `${Math.floor((index / 10) * totalProducts) || `0`}`,
        value: Math.floor((index / 10) * totalProducts),
        showLabel: true,
      };
    });
  }
  if (totalProducts < totalPDPs) {
    options.push({
      label: `${totalPDPs}`,
      value: totalPDPs,
      showLabel: true,
    });
  }
  return options;
};

export const autopilotOptions = [
  {
    label: i18next.t('automations.autopilot.settings.form.headOptions.headed'),
    value: 'headed',
  },
  {
    label: i18next.t(
      'automations.autopilot.settings.form.headOptions.headless'
    ),
    value: 'headless',
  },
];

export const autopilotFields = [
  {
    label: i18next.t(
      'automations.autopilot.settings.form.automated_fields.options.related_items'
    ),
    value: 'Related Items',
  },
  {
    label: i18next.t(
      'automations.autopilot.settings.form.automated_fields.options.meta_tags'
    ),
    value: 'Meta Tags',
  },
  {
    label: i18next.t(
      'automations.autopilot.settings.form.automated_fields.options.short_description'
    ),
    value: 'Short Description',
  },
  {
    label: i18next.t(
      'automations.autopilot.settings.form.automated_fields.options.long_description'
    ),
    value: 'Long Description',
  },
  {
    label: i18next.t(
      'automations.autopilot.settings.form.automated_fields.options.meta_description'
    ),
    value: 'Meta Description',
  },
];

export const filteredOptionDays = ({ values, value }) => {
  let filtered = [...values];
  const exists = filtered?.indexOf(value);
  if (exists !== -1) filtered = filtered?.filter((_o) => _o !== value);
  else filtered.push(value);
  return filtered;
};

export const filteredAutomatedFields = ({
  values,
  value,
  forceRemove = false,
}) => {
  const {
    marketplace_type: marketplaceType,
    value: automatedValue,
    label,
  } = value;
  const filtered = values ? [...values] : [];
  const existMarketplace = filtered.findIndex(
    (_m) =>
      _m?.marketplace_type === marketplaceType && _m?.name === automatedValue
  );
  if (existMarketplace === -1) {
    filtered.push({
      display_name: automatedValue,
      name: automatedValue.toLocaleLowerCase().replaceAll(' ', '_'),
      marketplace_type: marketplaceType,
      enabled: true,
      label,
    });
  } else {
    const existOption = filtered[existMarketplace];
    let marketPlaceSelected = JSON.parse(JSON.stringify({ ...existOption }));
    marketPlaceSelected = {
      ...marketPlaceSelected,
      enabled: !marketPlaceSelected?.enabled,
    };
    filtered[existMarketplace] = marketPlaceSelected;
    if (forceRemove) filtered.splice(existMarketplace, 1);
  }
  return filtered;
};

export const valuesByAutomatedFields = (values, marketPlace) => {
  let filtered = [...values];
  filtered = filtered
    .filter((_v) => _v?.enabled && _v?.marketplace_type === marketPlace)
    .flatMap((_v) => _v?.name);
  return filtered;
};

export const valuesByMarketPlace = (values, defaultOptions) => {
  let marketPlaceValues = [];
  values.map((_m) => {
    _m?.selectedOptions.map((_mv) => {
      marketPlaceValues = filteredAutomatedFields({
        values: [...marketPlaceValues],
        value: {
          marketplace_type: _m?.type,
          value: _mv,
          label: defaultOptions?.find((_o) => _o?.value === _mv)?.label,
        },
      });
    });
  });
  return [...marketPlaceValues];
};

export const validationFormSchema = Yup.object().shape({
  automated_fields: Yup.array()
    .min(
      1,
      i18next.t(
        'automations.autopilot.settings.form.validations.automatedFieldsRequired'
      )
    )
    .test(
      'at-least-one-enabled',
      i18next.t(
        'automations.autopilot.settings.form.validations.automatedFieldsEnabled'
      ),
      (items) => {
        return items.some((item) => item?.enabled);
      }
    ),
  categories: Yup.array().min(
    1,
    i18next.t(
      'automations.autopilot.settings.form.validations.categoriesRequired'
    )
  ),
});

export const propsLayoutCommon = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
};

export const buildAutomatedFieldsOptions = (data) => {
  let automatedFieldsOptions = (data?.automated_fields || []).map((_af) => ({
    label: _af?.display_name,
    value: _af?.name,
  }));
  if (data?.seo_field) {
    automatedFieldsOptions = [
      ...automatedFieldsOptions,
      {
        label: i18next.t('automations.dataWizard.form.keywords.title'),
        value: 'seo_keywords',
      },
    ];
  }
  return automatedFieldsOptions;
};

export const buildAutomatedFieldsInitialValues = (data) => {
  let automatedFieldsInitialValues =
    data?.automated_fields.map((af) => ({
      ...af,
    })) || [];
  if (data?.seo_field) {
    automatedFieldsInitialValues = [
      ...automatedFieldsInitialValues,
      {
        display_name: i18next.t('automations.dataWizard.form.keywords.title'),
        enabled: data?.seo_field_enabled,
        marketplace_type: data?.integration_type,
        name: 'seo_keywords',
      },
    ];
  }
  return automatedFieldsInitialValues;
};

export const validateSEOField = (autopilotData, automatedFields) => {
  let enabled = false;
  if (!!autopilotData?.seo_field) {
    const seoFieldIndex = automatedFields?.findIndex(
      (_af) => _af.name === 'seo_keywords'
    );
    if (seoFieldIndex > -1) {
      const seoField = automatedFields?.splice(seoFieldIndex, 1)[0];
      enabled = seoField?.enabled || false;
    }
  }
  return enabled;
};

const contentStructureTypeConditions = [
  {
    condition: ({ htmlOptional, html }) => !htmlOptional && !html,
    output: () => [{ value: 'text', selected: true }],
  },
  {
    condition: ({ htmlOptional, html }) => !htmlOptional && html,
    output: () => [{ value: 'html', selected: true }],
  },
  {
    condition: ({ htmlOptional }) => htmlOptional,
    output: (html) => [
      { value: 'text', selected: !html },
      { value: 'html', selected: html },
    ],
  },
];

export function generateContentStructureText({ htmlOptional, html }) {
  const matchedCondition = contentStructureTypeConditions?.find((cond) =>
    cond.condition({ htmlOptional, html })
  );
  return matchedCondition ? matchedCondition.output(html) : null;
}

export function formatContentStructureItemPlaceholder(placeholders) {
  return placeholders?.map((item) => `• ${item}`).join('\n');
}

const getContentStructureRulesFieldNames = ({ field, automatedFields }) => {
  return automatedFields
    ?.filter((af) =>
      field.hasOwnProperty('html')
        ? af.html === field.html && af.enabled === field.enabled
        : af.enabled === field.enabled
    )
    .map((af) => af.display_name)
    .join(', ');
};

export const renderContentStructureRulesFields = ({
  automatedFields,
  theme,
}) => {
  const contentStructureRulesFields = [
    {
      title: i18n.t(
        'automations.dataWizard.form.contentStructureRules.states.activeText'
      ),
      html: false,
      enabled: true,
    },
    {
      title: i18n.t(
        'automations.dataWizard.form.contentStructureRules.states.activeHTML'
      ),
      html: true,
      enabled: true,
    },
    {
      title: i18n.t(
        'automations.dataWizard.form.contentStructureRules.states.inactive'
      ),
      enabled: false,
    },
  ];
  return contentStructureRulesFields.map((field, index) => {
    const fieldNames = getContentStructureRulesFieldNames({
      field,
      automatedFields,
    });
    return (
      fieldNames && (
        <Typography key={index} sx={{ color: theme.palette.text.alternative }}>
          <span className="font-semibold">{field.title}: </span>
          {fieldNames}
        </Typography>
      )
    );
  });
};

export const ruleTypeOptions = [
  'content_length',
  'custom',
  'must_be_one_of',
  'must_be_one_or_many_of',
  'must_include',
];

export const keywordRuleTypeOptions = ['custom'];

export const ruleApplicationTypeOptions = [
  'all_products',
  'certain_categories',
];

export const brandVoiceRuleTypeOptions = [
  'audience',
  'custom',
  'message',
  'tone',
  'shop_sells_many_brands',
  'brand_name',
];

const customBrandVoiceLabels = {
  shop_sells_many_brands: i18n.t(
    `automations.dataWizard.form.brandVoiceRules.ruleTypeOptions.custom_shop_sells_many_brands`
  ),
};
export const ruleOptions = {
  brand_voice: brandVoiceRuleTypeOptions.map((_v) => {
    return {
      value: _v,
      label: i18n.t(
        `automations.dataWizard.form.brandVoiceRules.ruleTypeOptions.${_v}`
      ),
      customLabel: customBrandVoiceLabels[_v],
    };
  }),
  content_structure: ruleTypeOptions.map((_v) => {
    return {
      value: _v,
      label: i18n.t(
        `automations.dataWizard.form.contentStructureRules.ruleTypeOptions.${_v}`
      ),
    };
  }),
  keyword_rules: keywordRuleTypeOptions.map((_v) => {
    return {
      value: _v,
      label: i18n.t(
        `automations.dataWizard.form.contentStructureRules.ruleTypeOptions.${_v}`
      ),
    };
  }),
};

export const ruleApplicationOptions = ruleApplicationTypeOptions.map((_v) => {
  return {
    value: _v,
    label: i18n.t(
      `automations.dataWizard.form.brandVoiceRules.ruleApplicationTypeOptions.${_v}`
    ),
  };
});

export const delimiterOptions = [',', ';', '|'];

export const mustIncludeOption = [
  'title',
  'featuresAndBenefits',
  'qAndASection',
  'custom',
];

export const mustIncludeOptions = mustIncludeOption.map((_v) => {
  const text = i18n.t(
    `automations.dataWizard.form.contentStructureRules.mustIncludeOptions.${_v}`
  );
  return {
    value: text,
    label: text,
  };
});

export const getFormValuesByType = ({ ruleData, ruleFormType, categories }) => {
  const initialValuesByForm = {
    brand_voice: {
      field: null,
      audience: ruleData?.audience ?? [],
      message: ruleData?.message ?? null,
      rule_type: ruleData?.rule_type || null,
      text: !ruleData?.text ? undefined : ruleData?.text,
      tone: ruleData?.tone ?? [],
      rule_application: !!ruleData?.categories_identifiers?.length
        ? 'certain_categories'
        : 'all_products',
      categories: ruleData?.categories_identifiers
        ? ruleData?.categories_identifiers.map((cat) => ({ value: cat }))
        : [],
      brand_name: ruleData?.brand_name ?? null,
    },
    content_structure: {
      field: null,
      max_length: ruleData?.max_length || null,
      max_length_unit: ruleData?.max_length_unit || null,
      must_be: ruleData?.must_be || [],
      must_be_delimiter: ruleData?.must_be_delimiter || null,
      must_include: ruleData?.must_include || [],
      rule_type: ruleData?.rule_type || null,
      text: !ruleData?.text ? undefined : ruleData?.text,
      rule_application: !!ruleData?.categories_identifiers?.length
        ? 'certain_categories'
        : 'all_products',
      categories: ruleData?.categories_identifiers
        ? ruleData?.categories_identifiers.map((cat) => ({ value: cat }))
        : [],
    },
    keyword_rules: {
      field: 'keywords',
      rule_type: ruleData?.rule_type || 'custom',
      text: !ruleData?.text ? undefined : ruleData?.text,
      rule_application: !!ruleData?.categories_identifiers?.length
        ? 'certain_categories'
        : 'all_products',
      categories: ruleData?.categories_identifiers
        ? ruleData?.categories_identifiers.map((cat) => ({ value: cat }))
        : [],
    },
  };

  return initialValuesByForm[ruleFormType];
};
