import { useMediaQuery } from '@mui/material';

export default function Dialog(theme) {
  const rootElement = document.getElementById('root');
  const mobileView = useMediaQuery('(max-width: 768px)');

  return {
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
      styleOverrides: {
        root: {
          background: theme.palette.dialog.background,
        },
        paper: {
          maxWidth: mobileView ? '700px' : '900px',
          borderRadius: 10,
          padding: mobileView ? 25 : 45,
          background: theme.palette.dialog.backgroundContainer,
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        container: rootElement,
      },
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          paddingTop: mobileView ? 15 : 35,
          paddingBottom: mobileView ? 15 : 35,
          paddingLeft: mobileView ? 25 : 45,
          paddingRight: mobileView ? 25 : 45,
          gap: 35,
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {
        container: rootElement,
      },
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: 15,
          gap: 30,
        },
        spacing: {},
      },
    },
  };
}
