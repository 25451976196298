// @import Dependencies
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// @import Utilities
import { nomenclatureSnack } from '@utils/nomenclature';
import { snack } from '@utils/snackbar';
import { getUpdatedAutomation } from '@utils/index';
import { handleRowLimitException, pollRequest } from '@utils/slices';

// @import Services
import {
  getAutomationBatchRowsAPI,
  createAutomationAPI,
  getAutomationsAPI,
  getAutomationAPI,
  pushToCmsAPI,
  editOutputAPI,
  reoptimizeAutomationBatchRowAPI,
  rerunAutomationBatchrowAPI,
  updateAutomationAPI,
  getRerunAutomationBatchrowAPI,
  getReoptimizedAutomationBatchRowAPI,
} from '@api/automation';

const initialLoadings = {
  loadingAutomations: false,
  loadingPushToCms: false,
  loadingEditOutput: false,
  loadingRerunAutomatiomBatchRow: false,
  loadingReoptimizeAutomationBatchRow: false,
  loadingUpdateAutomation: false,
  loadingAutomationBatchRows: false,
};

export const initialState = {
  loadings: initialLoadings,
  showAutomationSuccessView: false,
  automations: [],
  newAutomationStep: 1,
  automationDetailScreen: {
    loading: false,
    table: {
      loading: false,
      rowsRender: 10,
      updatedRow: null,
      updatedProductRow: null,
      updatingRowId: null,
      updatingProductRowId: null,
      showUniqueId: false,
      showApprovedAssets: false,
      renderHtmlContent: true,
      pagination: {
        currentPage: 0,
      },
    },
  },
  selection: {
    selectedRows: [],
    approvedRows: [],
    allAssetsIds: [],
    selectAllApprovedRows: false,
    selectAllRows: false,
    selectAllAssets: false,
  },
  editingResultId: null,
  semrush: {
    phrase_related: {
      loading: false,
      rows: [],
    },
    phrase_fullsearch: {
      loading: false,
      rows: [],
    },
  },
};
// ------------------THUNKS----------------

export const getAutomationBatchRows = createAsyncThunk(
  'automation/getAutomationBatchRows',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getAutomationBatchRowsAPI(
      body
    );
    if (isSuccessful) {
      return { rowsResponse: data, page: body?.page || 1 };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const createAutomation = createAsyncThunk(
  'automation/createAutomation',
  async (body, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await createAutomationAPI(body);
    if (isSuccessful) {
      return { ...res, statusKey };
    }
    handleRowLimitException({
      statusKey,
      dispatch: thunkAPI.dispatch,
    });
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const updateAutomation = createAsyncThunk(
  'automation/updateAutomation',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await updateAutomationAPI(params);
    if (isSuccessful) {
      return { data: { id: params?.id, ...params?.body }, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getAutomations = createAsyncThunk(
  'automation/getAutomations',
  async (_, thunkAPI) => {
    const {
      data: automationRes,
      isSuccessful: isAutomationSuccessful,
      statusKey: automationCode,
    } = await getAutomationsAPI();
    if (isAutomationSuccessful) {
      return { automationRes };
    }
    return thunkAPI.rejectWithValue(automationCode);
  }
);

export const getAutomation = createAsyncThunk(
  'automation/getAutomation',
  async (id, thunkAPI) => {
    const { data: res, isSuccessful, statusKey } = await getAutomationAPI(id);
    if (isSuccessful) {
      return res;
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const pushToCms = createAsyncThunk(
  'automation/pushToCMS',
  async (body, thunkAPI) => {
    const { data: res, isSuccessful, statusKey } = await pushToCmsAPI(body);
    if (isSuccessful) return { ...res, statusKey };
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const editOutput = createAsyncThunk(
  'automation/editOutput',
  async ({ body, id, extraParams }, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await editOutputAPI({ body, resultId: id, extraParams });
    if (isSuccessful) {
      return { ...body, statusKey, id, ...extraParams };
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const rerunWorkflowBatchrow = createAsyncThunk(
  'automation/rerunWorkflowBatchrow',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await rerunAutomationBatchrowAPI(
      params
    );
    if (!isSuccessful) {
      handleRowLimitException({ statusKey, dispatch: thunkAPI.dispatch });
      return thunkAPI.rejectWithValue({ data, statusKey });
    }
    const { pollData, pollStatusKey, isPollSuccessful } = await pollRequest({
      params: { id: data?.id },
      request: getRerunAutomationBatchrowAPI,
    });
    if (!isPollSuccessful) {
      handleRowLimitException({
        statusKey: pollStatusKey,
        dispatch: thunkAPI.dispatch,
      });
      return thunkAPI.rejectWithValue({
        data: pollData,
        statusKey: pollStatusKey,
      });
    }
    return {
      data: pollData,
      statusKey: pollStatusKey,
      rowId: params?.rowId,
      productId: params?.productId,
    };
  }
);

export const reoptimizeAutomationBatchRow = createAsyncThunk(
  'automation/reoptimizeAutomationBatchRow',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await reoptimizeAutomationBatchRowAPI(params);
    if (!isSuccessful) {
      handleRowLimitException({ statusKey, dispatch: thunkAPI.dispatch });
      return thunkAPI.rejectWithValue({ ...data, statusKey });
    }
    const { pollData, pollStatusKey, isPollSuccessful } = await pollRequest({
      params: { id: data?.id },
      request: getReoptimizedAutomationBatchRowAPI,
    });
    if (!isPollSuccessful) {
      handleRowLimitException({
        statusKey: pollStatusKey,
        dispatch: thunkAPI.dispatch,
      });
      return thunkAPI.rejectWithValue({
        data: pollData,
        statusKey: pollStatusKey,
      });
    }
    return {
      ...pollData,
      statusKey: pollStatusKey,
    };
  }
);

export const sharedExtraReducers = (builder) => {
  builder
    .addCase(createAutomation.pending, (state) => {
      state.loadings = { ...state.loadings, loadingAutomations: true };
    })
    .addCase(createAutomation.fulfilled, (state, action) => {
      state.loadings = { ...state.loadings, loadingAutomations: false };
      state.automations.unshift({ ...action.payload, type: 'cms' });
      state.showAutomationSuccessView = true;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(createAutomation.rejected, (state) => {
      state.loadings = { ...state.loadings, loadingAutomations: false };
    })
    .addCase(updateAutomation.pending, (state) => {
      state.loadings = { ...state.loadings, loadingUpdateAutomation: true };
    })
    .addCase(updateAutomation.fulfilled, (state, action) => {
      state.loadings = { ...state.loadings, loadingUpdateAutomation: false };
      const updatedAutomation = action?.payload?.data;
      const currentAutomationJobs = [...state.automations];
      const foundAutomationIndex = currentAutomationJobs.findIndex(
        (item) => item.id === updatedAutomation.id
      );
      if (foundAutomationIndex !== -1) {
        state.automations[foundAutomationIndex] = {
          ...state.automations[foundAutomationIndex],
          ...updatedAutomation,
        };
      }
      state.automationDetailScreen.loading = false;

      const isFavoriteChange = Object.keys(updatedAutomation).some(
        (item) => item === 'is_favorite'
      );

      if (!isFavoriteChange) {
        nomenclatureSnack({
          type: 'success',
          message: action?.payload?.statusKey,
        });
      }
    })
    .addCase(updateAutomation.rejected, (state, action) => {
      state.loadings = { ...state.loadings, loadingUpdateAutomation: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getAutomations.pending, (state) => {
      state.loadings = { ...state.loadings, loadingAutomations: true };
    })
    .addCase(getAutomations.fulfilled, (state, action) => {
      const { automationRes } = action.payload;
      const dbAutomations = [...automationRes]
        .sort((op1, op2) => {
          if (op1.created_at < op2.created_at) return 1;
          else if (op1.created_at > op2.created_at) return -1;
          else return 0;
        })
        .filter((a) => a.state !== 'failure');
      const currentAutomations = [...state.automations];
      const processingAutomations = currentAutomations.filter(
        (automation) =>
          automation.state === 'processing' || automation.state === 'sending'
      );
      if (processingAutomations.length) {
        const updatedAutomation = getUpdatedAutomation(
          processingAutomations,
          dbAutomations
        );
        if (updatedAutomation) {
          snack(
            'success',
            'cmsIntegration.snacks.success.updatedAutomation',
            {
              workflow_name: updatedAutomation?.name,
            },
            {},
            `/app/automations/automation/${updatedAutomation?.id}`
          );
        }
      }
      state.automations = dbAutomations;
      state.loadings = { ...state.loadings, loadingAutomations: false };
    })
    .addCase(getAutomations.rejected, (state, action) => {
      state.error = action.payload;
      state.loadings = { ...state.loadings, loadingAutomations: false };
    })
    .addCase(getAutomation.pending, (state) => {
      state.automationDetailScreen.loading = true;
    })
    .addCase(getAutomation.fulfilled, (state, action) => {
      state.automations = [{ ...action.payload }];
      state.automationDetailScreen.loading = false;
    })
    .addCase(getAutomation.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
      state.automationDetailScreen.loading = false;
    })
    .addCase(pushToCms.pending, (state) => {
      state.loadings = { ...state.loadings, loadingPushToCms: true };
    })
    .addCase(pushToCms.fulfilled, (state, action) => {
      state.selection.selectedRows = [];
      state.selection.selectAllRows = false;
      state.loadings = { ...state.loadings, loadingPushToCms: false };
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(pushToCms.rejected, (state, action) => {
      state.selection.selectedRows = [];
      state.selection.selectAllRows = false;
      state.loadings = { ...state.loadings, loadingPushToCms: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(editOutput.pending, (state) => {
      state.loadings = {
        ...state.loadings,
        loadingEditOutput: true,
      };
    })
    .addCase(editOutput.fulfilled, (state, action) => {
      state.editingResultId = null;
      state.automationDetailScreen.table.updatedRow = {
        ...action.payload,
      };
      state.loadings = { ...state.loadings, loadingEditOutput: false };
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(editOutput.rejected, (state, action) => {
      state.editingResultId = null;
      state.loadings = { ...state.loadings, loadingEditOutput: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(rerunWorkflowBatchrow.pending, (state, action) => {
      state.loadings = {
        ...state.loadings,
        loadingRerunAutomatiomBatchRow: true,
      };
      state.automationDetailScreen.table.updatingRowId =
        action?.meta?.arg?.rowId;
    })
    .addCase(rerunWorkflowBatchrow.fulfilled, (state, action) => {
      state.loadings = {
        ...state.loadings,
        loadingRerunAutomatiomBatchRow: false,
      };
      const { data, rowId, productId, statusKey } = action.payload;

      state.automationDetailScreen.table.updatedRow = {
        ...data,
        id: rowId,
        productId,
      };
      state.automationDetailScreen.table.updatingRowId = null;
      nomenclatureSnack({
        type: 'success',
        message: statusKey,
      });
    })
    .addCase(rerunWorkflowBatchrow.rejected, (state, action) => {
      state.automationDetailScreen.table.updatingRowId = null;
      state.loadings = {
        ...state.loadings,
        loadingRerunAutomatiomBatchRow: false,
      };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(reoptimizeAutomationBatchRow.pending, (state, action) => {
      state.loadings.loadingReoptimizeAutomationBatchRow = true;
      state.automationDetailScreen.table.updatingProductRowId =
        action?.meta?.arg?.rowId;
    })
    .addCase(reoptimizeAutomationBatchRow.fulfilled, (state, action) => {
      state.loadings.loadingReoptimizeAutomationBatchRow = false;
      const data = action.payload;
      state.automationDetailScreen.table.updatedProductRow = {
        ...data,
      };
      state.automationDetailScreen.table.updatingProductRowId = null;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(reoptimizeAutomationBatchRow.rejected, (state) => {
      state.loadings.loadingReoptimizeAutomationBatchRow = false;
      state.automationDetailScreen.table.updatingProductRowId = null;
    })
    .addCase(getAutomationBatchRows.pending, (state, action) => {
      state.loadings.loadingAutomationBatchRows = true;
      state.automationDetailScreen.table.loading = true;
    })
    .addCase(getAutomationBatchRows.fulfilled, (state, action) => {
      const { rowsResponse: automationBatchRows, page } = action.payload;
      const approvedIds = [];
      const allIds = [];
      automationBatchRows.forEach((item) =>
        item.rows.forEach((row) => {
          if (row.approved) approvedIds.push(row.id);
          allIds.push(row.id);
        })
      );
      if (approvedIds.length === allIds.length)
        state.selection.selectAllAssets = true;
      state.selection.approvedRows = approvedIds;
      state.selection.allAssetsIds = allIds;
      state.loadings.loadingAutomationBatchRows = false;
      state.automationDetailScreen.table.loading = false;
      state.automationDetailScreen.table.currentPage = page;
    })
    .addCase(getAutomationBatchRows.rejected, (state, action) => {
      state.loadings.loadingAutomationBatchRows = false;
      state.automationDetailScreen.table.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey || 'default',
      });
    });
};

export const automationSlice = createSlice({
  name: 'automation',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    setDetailScreenTablePage: (state, action) => {
      state.automationDetailScreen.table.pagination.currentPage =
        action.payload;
    },
    setAutomationSuccessView: (state, action) => {
      state.showAutomationSuccessView = action.payload;
    },
    setNewAutomationStep: (state, action) => {
      state.newAutomationStep = action.payload;
    },
    setSelectedAutomationRows: (state, action) => {
      state.selection.selectedRows = action.payload;
    },
    setSelectAutomationAllRows: (state, action) => {
      state.selection.selectAllRows = action.payload;
    },
    setSelectAutomationAllAssets: (state, action) => {
      state.selection.selectAllAssets = action.payload;
    },
    setSelectedApprovedAutomationRows: (state, action) => {
      state.selection.approvedRows = action.payload;
    },
    setSelectApprovedAutomationAllRows: (state, action) => {
      state.selection.selectAllApprovedRows = action.payload;
    },
    setEditingAutomationResultId: (state, action) => {
      state.editingResultId = action.payload;
    },
    setShowAutomationUniqueId: (state, action) => {
      state.automationDetailScreen.table.showUniqueId = action?.payload;
    },
    setShowAutomationApprovedAssets: (state, action) => {
      state.automationDetailScreen.table.showApprovedAssets = action?.payload;
    },
    setRenderAutomationHtmlContent: (state, action) => {
      state.automationDetailScreen.table.renderHtmlContent = action?.payload;
    },
    toggleFavorite: (state, action) => {
      const { automation } = action.payload;
      const index = state.automations.findIndex((a) => a.id === automation.id);
      state.automations[index] = {
        ...automation,
        is_favorite: !automation.is_favorite,
      };
      const actionMessage = !automation.is_favorite ? 'added' : 'removed';
      snack('success', `automations.cards.favoriteActions.${actionMessage}`);
    },
    setAutomationRowRenders: (state, action) => {
      state.automationDetailScreen.table.rowsRender = action.payload;
    },
    resetAutomationUpdatedRow: (state) => {
      state.automationDetailScreen.table.updatedRow = null;
    },
    setNewCSVAutomation: (state, action) => {
      const csvData = { ...action.payload, type: 'csv' };
      state.automations.unshift(csvData);
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const {
  setDetailScreenTablePage,
  setAutomationSuccessView,
  setNewAutomationStep,
  setSelectedAutomationRows,
  setSelectAutomationAllRows,
  setSelectAutomationAllAssets,
  setSelectedApprovedAutomationRows,
  setSelectApprovedAutomationAllRows,
  setEditingAutomationResultId,
  toggleFavorite,
  setAutomationRowRenders,
  resetAutomationUpdatedRow,
  setNewCSVAutomation,
  setShowAutomationApprovedAssets,
  setShowAutomationUniqueId,
  setRenderAutomationHtmlContent,
} = automationSlice.actions;
export default automationSlice.reducer;

// ------------------SELECTORS-------------

export const selectNewAutomationStep = (state) =>
  state.automation.newAutomationStep;

export const selectShowAutomationSuccessView = (state) =>
  state.automation.showAutomationSuccessView;

export const selectAutomationLoadings = (state) => state.automation.loadings;

export const selectAutomationDetailScreenLoading = (state) =>
  state.automation.automationDetailScreen.loading;

export const selectAutomationDetailScreenLoadingTable = (state) =>
  state.automation.automationDetailScreen.table.loading;

export const selectAutomationDetailScreenTableData = (state) =>
  state.automation.automationDetailScreen.table;

export const selectAutomationDetailScreenTablePagination = (state) =>
  state.automation.automationDetailScreen.table.pagination;

export const selectDetailScreenTablePage = (state) =>
  state.automation.automationDetailScreen.table.pagination.currentPage;

export const selectAutomationDetailScreenTableUpdatingProductRowId = (state) =>
  state.automation.automationDetailScreen.table.updatingProductRowId;

export const selectAutomationDetailScreenTableUpdatingRowId = (state) =>
  state.automation.automationDetailScreen.table.updatingRowId;

export const selectAutomationDetailScreenTableUpdatedRow = (state) =>
  state.automation.automationDetailScreen.table.updatedRow;

export const selectAutomationTableRowsRender = (state) =>
  state.automation.automationDetailScreen.table.rowsRender;

export const selectDetailScreenTableSelectedRows = (state) =>
  state.automation.selection.selectedRows;

export const selectDetailScreenTableSelectAllRows = (state) =>
  state.automation.selection.selectAllRows;

export const selectAutomationDetailScreenTableUpdatedProductRow = (state) =>
  state.automation.automationDetailScreen.table.updatedProductRow;

export const selectDetailScreenTableSelectApprovedRows = (state) =>
  state.automation.selection.approvedRows;

export const selectDetailScreenTableSelectApprovedAllRows = (state) =>
  state.automation.selection.selectAllApprovedRows;

export const selectAutomations = (state) => state.automation.automations;

export const selectAutomationById = (state, id) =>
  state.automation.automations?.find((_w) => _w?.id === id);

export const selectIsEditingResultId = (state) =>
  state.automation.editingResultId;

export const selectAutomationDetailScreenTableSelectAllAssets = (state) =>
  state.automation.selection.selectAllAssets;

export const selectAutomationDetailScreenTableSelectAssetsIds = (state) =>
  state.automation.selection.allAssetsIds;
