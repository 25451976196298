// @import Dependencies
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';

// @import Custom components
import ErrorBoundary from '@shared/Error/Boundary';
import { WriterlyBackgroundLoader } from '@shared/Loader/Background';
import { GeneralFallback } from '@shared/Error/GeneralFallback';

// @import Selectors
import { selectUserData } from '@redux/slices/user';

const Loadable = (Component) => {
  const LoadableComponent = (props) => {
    const { user } = useSelector(selectUserData);
    return (
      <Suspense fallback={<WriterlyBackgroundLoader visible={true} />}>
        <ErrorBoundary
          fallback={
            <GeneralFallback
              renderReloadButton
              textVariant={'h2'}
              containerClasses="h-96 gap-20 p-20"
            />
          }
          userData={user}
        >
          <Component {...props} />
        </ErrorBoundary>
      </Suspense>
    );
  };

  return LoadableComponent;
};

export default Loadable;
