export default function IconButton(theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            '&:hover': {
              boxShadow: theme.palette.shadows[3],
            },
            // Primary
            ...(ownerState.color === 'primary' && {
              color: '#ffff',
              backgroundColor: theme.palette.primary.main,

              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
              '&.Mui-disabled': {
                color: theme.palette.grey[0],
                backgroundColor: theme.palette.primary.main,
                opacity: 0.4,
              },
            }),

            // Secondary
            ...(ownerState.color === 'secondary' && {
              backgroundColor: theme.palette.success.main,
              '&:hover': {
                backgroundColor: theme.palette.success.main,
              },
              '&.Mui-disabled': {
                color: theme.palette.grey[0],
                backgroundColor: theme.palette.success.main,
                opacity: 0.4,
              },
            }),
            ...(ownerState.variant === 'text' && {
              backgroundColor: theme.palette.background.default,
              border: `1px solid ${theme.palette.text.secondary}`,
              '&:hover': {
                backgroundColor: theme.palette.background.neutral,
              },
              '&.Mui-disabled': {
                color: theme.palette.grey[0],
                backgroundColor: theme.palette.background.neutral,
                opacity: 0.4,
              },
            }),
          };
        },
        sizeLarge: {
          height: 44,
          paddingLeft: 40,
          paddingRight: 40,
        },
        sizeMedium: {
          height: 44,
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  };
}
