import { makeRequest } from '@api/client';

const getCustomerPortalLink = async (body) => {
  return await makeRequest(`billing/portal-link`, {
    method: 'POST',
    body,
  });
};

const getCustomerPortalLinkCheckout = async (body) => {
  return await makeRequest(`billing/portal-link-checkout`, {
    method: 'POST',
    body,
  });
};

const updateCurrentPlanAPI = async (body) => {
  return await makeRequest(`billing/change-plan`, {
    method: 'POST',
    body,
  });
};
const cancelCurrentPlanAPI = async () => {
  return await makeRequest(`billing/cancel-plan`, {
    method: 'POST',
  });
};
const getPlansAPI = async () => {
  return await makeRequest(`billing/plans-tiers`, {
    method: 'GET',
  });
};

export {
  getCustomerPortalLink,
  getCustomerPortalLinkCheckout,
  updateCurrentPlanAPI,
  cancelCurrentPlanAPI,
  getPlansAPI,
};
