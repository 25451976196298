// @import Dependencies
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';

const TagManagerProvider = ({ gtmId, children }) => {
  useEffect(() => {
    const environment = import.meta.env.VITE_ENV;
    if (environment === 'production' && gtmId) {
      TagManager.initialize({ gtmId });
    }
  }, [gtmId]);
  return <>{children}</>;
};

TagManagerProvider.propTypes = {
  gtmId: PropTypes.any,
  children: PropTypes.node,
};

export default TagManagerProvider;
