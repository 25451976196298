// @import Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import i18next from 'i18next';

// @import Utils
import { snack } from '@utils/snackbar';

// @import Custom Components
import { GeneralFallback } from '@shared/Error/GeneralFallback';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    Sentry.withScope((scope) => {
      if (scope._session) {
        const generalData = this.props.userData
          ? JSON.parse(JSON.stringify(this.props.userData))
          : {};
        if (generalData.avatar) delete generalData.avatar; // Avoid the base64 string (limit error)
        scope.setExtras({
          reportedByBoundary: true,
          userReported: JSON.stringify(generalData),
          notReport: this.props.notReport,
        });
        Sentry.captureMessage(error, 'fatal');
      }
    });
    snack('error', i18next.t('errors.render'));
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) return this.props.fallback;
      else return <GeneralFallback />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  fallback: PropTypes.node,
  children: PropTypes.node,
  userData: PropTypes.object,
  notReport: PropTypes.bool,
};

export default ErrorBoundary;
