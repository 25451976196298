import { makeRequest } from '@api/client';

const getCmsIntegrationsAPI = async () => {
  return await makeRequest('api-integration/', {
    method: 'GET',
  });
};

const saveCmsConnectionRedirectAPI = async (body) => {
  return await makeRequest('api-integration-connect/', {
    method: 'POST',
    body,
  });
};

const saveCmsConnectionAPI = async (body) => {
  return await makeRequest('api-integration/', {
    method: 'POST',
    body,
  });
};

const editCmsConnectionAPI = async ({ body, id }) => {
  return await makeRequest(`api-integration/${id}/`, {
    method: 'PATCH',
    body,
  });
};

const deleteCmsConnectionAPI = async (id) => {
  return await makeRequest(`api-integration/${id}`, {
    method: 'DELETE',
  });
};

const requestCmsConnectionAPI = async (body) => {
  return await makeRequest('api-integration/request-custom-integration/', {
    method: 'POST',
    body,
  });
};

const getCmsProductsIntegrationByIdAPI = async (body) => {
  return await makeRequest(
    `api-integration/products/${body.id}/?page=${body?.page || 1}&page_size=${
      body?.pageSize || 10
    }`,
    {
      method: 'GET',
    }
  );
};

const getCmsIntegrationCategoriesAndProductsAPI = async (body) => {
  return await makeRequest(
    `api-integration/pull/${body?.id}/?page=${body?.page || 1}&page_size=${
      body?.pageSize || 10
    }`,
    {
      method: 'GET',
    }
  );
};

const getCmsIntegrationCategoriesAPI = async (body) => {
  return await makeRequest(
    `api-integration/categories/${body?.id}/?page=${
      body?.page || 1
    }&page_size=${body?.pageSize || 10}`,
    {
      method: 'GET',
    }
  );
};

export {
  getCmsIntegrationsAPI,
  saveCmsConnectionAPI,
  saveCmsConnectionRedirectAPI,
  editCmsConnectionAPI,
  deleteCmsConnectionAPI,
  requestCmsConnectionAPI,
  getCmsProductsIntegrationByIdAPI,
  getCmsIntegrationCategoriesAPI,
  getCmsIntegrationCategoriesAndProductsAPI,
};
