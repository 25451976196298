import { getCookie } from '@utils/react-utils';
import { getUrlStringParamsFromObject } from '@utils/browser';
import getStatusKey from '@api/regexByEndpoint';

const csrfToken = getCookie('csrftoken');

const API_URL = import.meta.env.VITE_BACKEND_API_URL;

const isSuccessful = (statusCode) => statusCode >= 200 && statusCode < 400;

const getMethod = (method = 'get') => {
  const validMethods = ['get', 'post', 'patch', 'delete', 'put'];
  if (validMethods.includes(method.toLowerCase())) {
    return method.toUpperCase();
  }

  throw new Error(`Wrong method used. ${method} is not a valid method.`);
};

const getHeaders = (method, originalHeaders = {}) => {
  const headers = {
    ...originalHeaders,
    Authorization: localStorage.getItem('accessToken')
      ? `Token ${localStorage.getItem('accessToken')}`
      : undefined,
    'Content-Type': 'application/json',
    ...originalHeaders,
  };

  if (method.toLowerCase() !== 'get') {
    headers['X-CSRFToken'] = csrfToken;
    // DELETING HEADER FOR FORMDATA TO ALLOW BROWSER SET BOUNDARY VALUE (WE CAN´T DO THAT)
    if (headers['Content-Type'] === 'multipart/form-data') {
      delete headers['Content-Type'];
    }
  }

  return headers;
};

const getBody = (body, headers) => {
  const contentType = headers['Content-Type']
    ? headers['Content-Type'].toLowerCase()
    : null;
  if (contentType === 'application/json' && body != null) {
    return JSON.stringify(body);
  }
  return body;
};

const makeRequest = async (
  url,
  options = {},
  extraAttributes = {},
  retries = 3
) => {
  const {
    params: rawParams,
    method: rawMethod,
    headers: rawHeaders,
    body: rawBody,
    cache: rawCache,
    signal,
  } = options;

  let requestUrl = API_URL + url;
  const params = getUrlStringParamsFromObject(rawParams);
  const method = getMethod(rawMethod);
  const headers = getHeaders(method, rawHeaders);
  const body = getBody(rawBody, headers);
  const cache = rawCache || 'default';

  if (params && params !== '') {
    requestUrl = `${requestUrl}?${params}`;
  }
  let response = {};
  try {
    response = await fetch(requestUrl, {
      body,
      method,
      cache,
      headers,
      signal,
      credentials: 'same-origin',
    });

    if (response.status >= 500 && retries > 0) {
      const formattedResponse = await response.json().catch(() => ({}));
      if (formattedResponse.status !== 500000) {
        // exponential backoff in case it's not a Django 500000 error
        await new Promise((resolve) =>
          setTimeout(resolve, 2 ** (4 - retries) * 1000)
        );

        return makeRequest(url, options, extraAttributes, retries - 1);
      }
    }
  } catch (error) {
    response = {
      code: undefined,
      data: undefined,
      isSuccessful: false,
    };
  }
  const customResponse = {
    response: response,
    urlMetadata: { statusKey: `${method}_${url}` },
  };

  return await handleResponse(customResponse, extraAttributes);
};

const handleResponse = async ({ response, urlMetadata }, extraAttributes) => {
  let responseText = '';
  let parsedResponse = '';
  const code = response.status;
  try {
    if (response.body != null) {
      if (extraAttributes?.isBlob) {
        responseText = await response.blob();
      } else responseText = await response.text();
    }
  } catch (err) {
    responseText = JSON.stringify({
      status: '500000',
    });
  }

  try {
    parsedResponse = JSON.parse(responseText);
  } catch (ex) {
    parsedResponse = responseText;
  }

  if (
    code === 401 &&
    parsedResponse.status === 401000 &&
    !extraAttributes?.noAuthReload
  ) {
    window.location.reload();
    localStorage.clear();
  }

  return {
    code,
    data: parsedResponse,
    isSuccessful: isSuccessful(code),
    statusKey: `${getStatusKey(urlMetadata.statusKey)}.${
      parsedResponse?.status || code
    }`,
  };
};

export { makeRequest, handleResponse, isSuccessful };
