import { makeRequest } from '@api/client';

const getAutomationBatchRowsAPI = async (body) => {
  return await makeRequest(
    `workflows-batchrows/${body.id}?page=${body?.page || 1}&page_size=${
      body?.pageSize || 10
    }`,
    {
      method: 'GET',
    }
  );
};

const createAutomationAPI = async (body) => {
  return await makeRequest(`automation/`, {
    method: 'POST',
    body,
  });
};

const getAutomationsAPI = async () => {
  return await makeRequest(`automation/`, {
    method: 'GET',
  });
};

const getAutomationAPI = async (id) => {
  return await makeRequest(`automation/${id}/`, {
    method: 'GET',
  });
};

const updateAutomationAPI = async ({ body, id }) => {
  return await makeRequest(`automation/${id}/`, {
    method: 'PATCH',
    body,
  });
};

const pushToCmsAPI = async (body) => {
  return await makeRequest(`automation-push`, {
    method: 'POST',
    body,
  });
};

const rerunAutomationBatchrowAPI = async ({ body, rowId }) => {
  return await makeRequest(`automation-rerun-post/${rowId}`, {
    method: 'POST',
    body,
  });
};
const getRerunAutomationBatchrowAPI = async ({ id }) => {
  return await makeRequest(`automation-rerun-get/${id}`, {
    method: 'GET',
  });
};

const reoptimizeAutomationBatchRowAPI = async ({ body, rowId }) => {
  return await makeRequest(`automation-reoptimize-post/${rowId}`, {
    method: 'POST',
    body,
  });
};

const getReoptimizedAutomationBatchRowAPI = async ({ id }) => {
  return await makeRequest(`automation-reoptimize-get/${id}`, {
    method: 'GET',
  });
};

const editOutputAPI = async ({ body, resultId }) => {
  return await makeRequest(`workflows-batchrows/${resultId}`, {
    method: 'PATCH',
    body,
  });
};

export {
  getAutomationBatchRowsAPI,
  createAutomationAPI,
  getAutomationsAPI,
  getAutomationAPI,
  pushToCmsAPI,
  editOutputAPI,
  reoptimizeAutomationBatchRowAPI,
  getReoptimizedAutomationBatchRowAPI,
  rerunAutomationBatchrowAPI,
  getRerunAutomationBatchrowAPI,
  updateAutomationAPI,
};
