import { enqueueSnackbar } from 'notistack';
import i18next from 'i18next';

export const snack = (type, text, extraParams, snackCustomConfig = {}, url) => {
  const snackConfig = {
    variant: 'writerlySnackBar',
    type: type,
    autoHideDuration: !url && 3000,
    TransitionProps: { direction: 'left' },
    anchorOrigin: {
      vertical: 'right',
      horizontal: 'top',
    },
    id: `${text}_${Date.now()}`,
    messageLinks: url && { [i18next.t(text, { ...extraParams })]: url },
    ...snackCustomConfig,
  };

  return enqueueSnackbar(i18next.t(text, { ...extraParams }), {
    id: text,
    ...snackConfig,
  });
};
